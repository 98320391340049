<template>
  <div class="units-detail">
    <el-breadcrumb separator="|">
      <el-breadcrumb-item :to="{ name: 'units-management' }">记账单位管理</el-breadcrumb-item>
      <el-breadcrumb-item>记账单位详情</el-breadcrumb-item>
    </el-breadcrumb>
    <ym-table-list :data=data
                   :columns="columns"
                   operatorWidth="100px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">
      <!-- 顶部记账单位详细信息 -->
      <template #topInfo>
        <div class="unit-detail">
          <div class="unit-info">
            <div class="unit-name">
              <span class="title">记账单位名称：</span>
              <span class="info">{{unitDetail.name}}</span>
            </div>
            <div class="unit-time">
              <div class="expiry">
                <span class="title">有效期至：</span>
                <span class="info">{{unitDetail.beginTime | timeFilter('YYYY.MM.DD')}} - {{unitDetail.endTime | timeFilter('YYYY.MM.DD')}}</span>
              </div>
              <div class="createTime">
                <span class="title">创建时间：</span>
                <span class="info">{{unitDetail.createTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</span>
              </div>
            </div>
          </div>
          <div class="unit-number">
            <div class="number-list">
              <span class="info">{{unitDetail.quantity}}</span>
              <span class="title">总数</span>
            </div>
            <div class="number-list">
              <span class="info">{{unitDetail.useQuantity}}</span>
              <span class="title">已使用</span>
            </div>
            <div class="number-list">
              <span class="info">{{unitDetail.remainderQuantity}}</span>
              <span class="title">当前余额</span>
            </div>
            <div class="number-list">
              <span class="info">{{unitDetail.confirmQuantity}}</span>
              <span class="title">已核销</span>
            </div>
          </div>
        </div>
      </template>

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 顶部右侧刷新 -->
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <!-- 顶部右侧搜索 -->
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索商家名称"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showUnitDetail(row)">详情</el-button>
      </template>

    </ym-table-list>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'

export default {
  components: {
    YmTableList
  },
  data() {
    return {
      data: [],
      columns: [{
        label: '商家名称',
        prop: 'name',
        align: 'center'
      },
      {
        label: '持有总数(个)',
        prop: 'sum',
        align: 'center'
      },
      {
        label: '已使用(个)',
        prop: 'useQuantity',
        align: 'center'
      },
      {
        label: '当前余额(个)',
        prop: 'remainderQuantity',
        align: 'center'
      },
      {
        label: '已核销(个)',
        prop: 'confirmQuantity',
        align: 'center'
      }],
      pageLayout: 'prev, pager, next, jumper',
      total: 10, // 数据总数
      search: '', // 搜索内容
      row: {}, // 当前行信息
      unitDetail: {},
      page: 1,
      size: 10
    }
  },
  mounted() {
    // 加载对应记账单位的详情 data
    this.getUnit()
    // this.data =
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.search = ''
      this.handleSearch()
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      this.page = page
      this.getList()
    },
    // 获取记账单位信息
    getUnit() {
      this.$http.unit.getUnit({ id: this.$route.query.id }).then(res => {
        if (res.data.code === 0) {
          this.unitDetail = res.data.data
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    // 通过记账单位id获取分配的商家列表
    getList() {
      this.$http.unit.getUnitDetail({
        accountingId: this.unitDetail.id,
        page: this.page,
        size: this.size,
        userName: this.search
      }).then(res => {
        if (res.data.code === 0) {
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
  }

}
</script>

<style lang="scss">
.units-detail {
  .el-breadcrumb {
    margin-bottom: 31px;
    &__item {
      line-height: 20px;
      .is-link {
        color: #b3b4ba;
        &:hover {
          color: $--color-primary;
        }
      }
      &[aria-current="page"] {
        font-size: 20px;
        .el-breadcrumb__inner {
          font-weight: 600;
          color: $--color-text-primary;
        }
      }
    }
    &__separator {
      font-size: 16px;
    }
  }
  .unit-detail {
    padding: 24px 0 26px 32px;
     display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .unit-info {
      font-size: 16px;
      .title {
        display: block;
        margin-bottom: 10px;
        color: #b3b4ba;
      }
      .info {
        display: block;
        color: $--color-text-primary;
        font-weight: 600;
      }
      .unit-time {
        margin-top: 27px;
        .expiry {
          float: left;
        }
        .createTime {
          float: left;
          margin-left: 90px;
        }
      }
    }
    .unit-number {
      margin-top: 16px;
      .number-list {
        float: left;
        text-align: center;
        margin-top: 20px;
        margin-right: 115px;
        .title {
          display: block;
          font-size: 16px;
          color: #b3b4ba;
        }
        .info {
          display: block;
          margin-bottom: 20px;
          font-size: 42px;
          font-weight: 700;
          color: $--color-text-primary;
        }
      }
    }
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
}
</style>
